<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama Role
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.name"
                v-validate="'required'"
                class="uk-input"
                type="text"
                name="name"
                placeholder="Masukan nama role"
                :class="{'uk-form-danger': errors.has('name')}"
              >
              <div
                v-show="errors.has('name')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('name') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Catatan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <textarea
                v-model="formData.description"
                class="uk-textarea"
                rows="3"
                name="catatan"
                placeholder="Masukan catatan"
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <!-- <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">Akses</div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <select
                  class="uk-select"
                  v-model="accessChoose"
                  name="akses"
                  v-validate="'required'"
                >
                  <option value="">Pilih</option>
                  <option value="1">Admin Dashboard dan Aplikasi</option>
                  <option value="2">Admin Dashboard</option>
                  <option value="3">Aplikasi</option>
                </select>
                <div class="uk-text-small uk-text-danger" v-show="errors.has('akses')">{{ errors.first('akses') }}</div>
              </div>
            </div>
          </div> -->
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Status
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl"
              style="display:flex"
            >
              <input
                v-model="statusSwitch"
                class="switcher"
                type="checkbox"
              >
              <span
                v-if="statusSwitch"
                class="uk-text-success label-unbackground"
              >{{ status.ACTIVE }}</span>
              <span
                v-else
                class="uk-text-danger label-unbackground"
              >{{ status.INACTIVE }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-margin-top">
        <div class="uk-overflow-auto">
          <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
            <thead class="thead-table-paranje">
              <tr>
                <th class="uk-width-large">
                  <span class="uk-text-bold">Menu</span>
                </th>
                <th class="uk-width-small uk-text-center">
                  <span class="uk-text-bold">View</span>
                </th>
                <th class="uk-width-small uk-text-center">
                  <span class="uk-text-bold">Add</span>
                </th>
                <th class="uk-width-small uk-text-center">
                  <span class="uk-text-bold">Edit</span>
                </th>
                <th class="uk-width-small uk-text-center">
                  <span class="uk-text-bold">Delete</span>
                </th>
              </tr>
            </thead>
            <template>
              <tbody>
                <tr
                  v-for="(v, i) in collectDashboardMenu"
                  :key="i"
                >
                  <td>{{ v.name }}</td>
                  <td class="uk-text-center">
                    <input
                      class="uk-checkbox"
                      type="checkbox"
                      :checked="v.view"
                      :disabled="v.add || v.edit || v.delete"
                      @change="checkRulesDashboard($event, i, `view`)"
                    >
                  </td>
                  <td class="uk-text-center">
                    <input
                      v-show="v.name !== 'Dashboard'"
                      class="uk-checkbox"
                      type="checkbox"
                      :checked="v.add"
                      @change="checkRulesDashboard($event, i, `add`)"
                    >
                  </td>
                  <td class="uk-text-center">
                    <input
                      v-show="v.name !== 'Dashboard'"
                      class="uk-checkbox"
                      type="checkbox"
                      :checked="v.edit"
                      @change="checkRulesDashboard($event, i, `edit`)"
                    >
                  </td>
                  <td class="uk-text-center">
                    <input
                      v-show="v.name !== 'Dashboard'"
                      class="uk-checkbox"
                      type="checkbox"
                      :checked="v.delete"
                      @change="checkRulesDashboard($event, i, `delete`)"
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>
      <div class="uk-margin-medium-top">
        <b>Aplikasi</b>
        <div
          style="margin-top: 16px;"
          class="uk-overflow-auto"
        >
          <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
            <thead class="thead-table-paranje">
              <tr>
                <th class="uk-width-small">
                  <span class="uk-text-bold">Menu</span>
                </th>
                <th class="uk-width-small uk-text-center">
                  <span class="uk-text-bold">Akses</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(v, i) in collectMobileMenu"
                :key="i"
              >
                <td>{{ v.name || '-' }}</td>
                <td class="uk-text-center">
                  <input
                    class="uk-checkbox"
                    type="checkbox"
                    :checked="v.access"
                    @change="checkRulesMobile($event, i, `access`)"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="uk-width-1-1 uk-text-right uk-margin-top">
        <button
          class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
          type="button"
          @click="goToBack"
        >
          <img
            v-lazy="`${images}/icon/close.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Batal
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
          type="button"
          @click="showSaveConfirmModal"
        >
          <img
            v-lazy="`${images}/icon/save.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import 'vue2-datepicker/index.css'
import { STATUS } from '@/utils/constant'
import sidebarJson from "@/components/globals/sidebar/sidebar.json"
import sidebarFarmJson from "@/components/globals/sidebar/sidebar-farm.json"
import sidebarUserJson from "@/components/globals/sidebar/sidebar-user.json"
import sidebarMasterJson from "@/components/globals/sidebar/sidebar-master.json"
import sidebarMobileJson from "../mobileAccess.json"

export default {
  components: {},
  props: {},
  data() {
    return {
      isShowFarm: false,
      isLoading: false,
      images: PREFIX_IMAGE,
      sidebar: sidebarJson,
      sidebarFarm: sidebarFarmJson,
      sidebarUser: sidebarUserJson,
      sidebarMaster: sidebarMasterJson,
      sidebarMobile: sidebarMobileJson,
      status: STATUS,
      statusSwitch: true,
      formData: {
        name: '',
        description: '',
        status: ''
      },
      accessChoose: '',
      roleAccess: [],
      collectDashboardMenu: [],
      collectMobileMenu: sidebarMobileJson,
      collectAllMenu: []
    }
  },
  async mounted() {
    await this.buildDataMenu()
  },
  methods: {
    checkRulesDashboard ($event, i, valueRules) {
      const checkedInput = $event.target.checked
      if ((valueRules === 'edit' || valueRules === 'delete' || valueRules === 'add') && checkedInput == true) {
        this.collectDashboardMenu[i][valueRules] = checkedInput
        this.collectDashboardMenu[i]['view'] = checkedInput
      } else {
        this.collectDashboardMenu[i][valueRules] = checkedInput
      }
    },
    checkRulesMobile ($event, i, valueRules) {
      const checkedInput = $event.target.checked
      this.collectMobileMenu[i][valueRules] = checkedInput
    },
    ...mapMutations({
      setModalAdd: 'role/SET_MODAL_ADD'
    }),
    async buildDataMenu() {
      const app = this
      const allSidebar = [...app.sidebar, ...app.sidebarFarm, ...app.sidebarMaster, ...app.sidebarUser]
      allSidebar.forEach(element => {
        if (element.childDisplay) {
          for (const child of element.children) {
            const obj = {
              name: child.name,
              view:false,
              add:false,
              edit:false,
              delete:false,
              access:false,
              type: 'dashboard'
            }
            app.collectDashboardMenu.push(obj)
          }
        } else {
          const obj = {
            name: element.name,
            view:false,
            add:false,
            edit:false,
            delete:false,
            access:false,
            type: 'dashboard'
          }
          app.collectDashboardMenu.push(obj)
        }
      })
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      const distinctAllRoles = [...this.collectDashboardMenu, ...this.collectMobileMenu]
      this.formData['role_management'] = distinctAllRoles
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          window.UIkit.modal('#modal-save-confirm').show()
          this.formData.status = this.statusSwitch
          this.setModalAdd(this.formData)
        }
      })
    }
  }
}
</script>

<style scoped>
.uk-checkbox:checked {
  background-color: #025231 !important;
}
.uk-checkbox:focus {
    outline: none;
    border-color: #025231 !important;
}
.uk-table-striped tbody tr:nth-of-type(odd) {
    border-top: 1px solid #F0F3F5 !important;
    border-bottom: 10px solid #F0F3F5 !important;
}
</style>
